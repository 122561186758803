import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Outlet } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../assets/img/logo.webp";
import { Helmet } from "react-helmet";

const MainLayout = () => {
  const headerElements = [
    "Inicio",
    "Asociados y Aliados estratégicos",
    "Cursos",
    "Programas",
    "Eventos",
    "Noticias",
    // "Peru Innovation Startup Festival",
    "Contacto",
  ];
  const headerPaths = [
    "",
    "asociados",
    "cursos",
    "programas",
    "eventos",
    "noticias",
    // "koica",
    "contacto",
  ];
  return (
    <>
      <Helmet>
        <title> RUI - Red Universitaria de Innovacion </title>
        <meta property="og:title" content="RUI - Inicio" />
        <meta
          property="og:description"
          content="Red Universitaria de Innovacion"
        />
        <meta property="og:url" content="https://ruiperu.com" />
        <meta
          property="og:image"
          content="https://zg-s3.s3.us-east-2.amazonaws.com/rui/logo.webp"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <FloatingWhatsApp
        phoneNumber="+51976774367"
        accountName="Red Universitaria de Innovacion"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={logo}
        chatMessage="¡Hola! ¿Cómo te podemos ayudar? 😊"
        statusMessage="Normalmente responde en 1 hora"
      />
      <Header elements={headerElements} paths={headerPaths} />
      <main
        style={{
          paddingTop: "7rem",
          paddingBottom: "0rem",
          minHeight: "70vh",
        }}
      >
        <Outlet
          style={{
            marginBottom: "-2rem",
          }}
        />
      </main>
      <Footer elements={headerElements} paths={headerPaths} />
    </>
  );
};

export default MainLayout;
